<template>
  <div class="container">
    <div class="top_container">
      <span>报障报修</span>
    </div>
    <a-form-model ref="form" :model="form" :rules="rules" class="form_container">
      <a-form-model-item label="故障描述" prop="content">
        <a-textarea
          v-model="form.content"
          :auto-size="{ minRows: 4, maxRows: 4 }"
        />
      </a-form-model-item>
      <a-form-model-item label="产品类别" prop="big_category_id">
        <a-cascader :allowClear="false" @change="categoryChange" change-on-select :options="productCategoryList" placeholder="" v-model="form.category"/>
      </a-form-model-item>
      <a-form-model-item label="产品" prop="goods_id">
        <a-select v-model="form.goods_id">
          <a-select-option v-for="goods in productList" :key="goods.id" :value="goods.id">{{ goods.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="联系人" prop="linkman_name">
        <a-input v-model="form.linkman_name"/>
      </a-form-model-item>
      <a-form-model-item label="联系人电话" prop="linkman_mobile">
        <a-input v-model="form.linkman_mobile"/>
      </a-form-model-item>
      <a-form-model-item label="地址" prop="area">
        <a-cascader :fieldNames="{label: 'name', value: 'code', children: 'children'}" :options="addressList" placeholder="" v-model="form.area"/>
      </a-form-model-item>
      <a-form-model-item label="详细地址" prop="address">
        <a-input v-model="form.address" :maxLength="70"/>
      </a-form-model-item>
      <a-form-model-item label="附件" prop="attachment_list">
        <attachment-upload v-model="form.attachment_list"/>
      </a-form-model-item>
      <extend-field-form :field-list="fieldList" :form="form"></extend-field-form>
    </a-form-model>
    <div style="padding: 20px;text-align: center">
      <a-button type="primary" :loading="loading" :disabled="loading" @click="onSubmit">提交</a-button>
    </div>
    <a-modal
      title="进度查询码"
      :visible="visible"
      :footer="null"
      :maskClosable="false"
      forceRender
      :zIndex="99999"
      @cancel="closeModal"
    >
      <div class="qr_title">扫码查看反馈进度</div>
      <div class="qr_title">长按二维码保存图片或截图保存图片</div>
      <div style="display: none" ref="qrCodeUrl"></div>
      <div style="display: flex;justify-content: center;margin-bottom: 10px;width:100%;margin-top: 10px;" id="qrCode">
        <img style="position: relative; z-index: 99999" :src="qrSrc" alt="">
      </div>
    </a-modal>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import AttachmentUpload from '@/components/Attachment/upload'
import ExtendFieldForm from '@/components/ExtendField/form'
import request from '@/utils/request';
import enums from "../utils/enums";
export default {
  components: { ExtendFieldForm, AttachmentUpload },
  data () {
    return {
      enums,
      form: {},
      rules: {
        linkman_name: [{ required: true, message: '请输入联系人'}],
        linkman_mobile: [
          { required: true, message: '请输入联系人电话'},
        ],
        big_category_id: [{ required: true, message: '请选择产品类型', trigger: 'blur' }],
        content: [
          { required: true, message: '请输入故障描述'},
          { max: 255, message: '长度超出限制' }
        ],
        goods_id: [{ required: true, message: '请选择产品', trigger: 'blur' }],
        fault: [
          {
            type: 'array',
            required: true,
            message: '请选择故障类别',
            trigger: 'change'
          }
        ]
      },
      addressList: [],
      faultList: [],
      fieldList: [],
      visible: false,
      productCategoryList: [],
      productList: [],
      qrCode: null,
      qrSrc: '',
      loading: false
    }
  },
  watch: {
    'form.category' (val) {
      if (val) {
        if (val[0]) {
          this.form.big_category_id = val[0]
        } else {
          this.$delete(this.form, 'big_category_id')
        }
        if (val[1]) {
          this.form.small_category_id = val[1]
        } else {
          this.$delete(this.form, 'small_category_id')
        }
      }
    }
  },
  created: async function () {
    await request({
      url: 'region/tree'
    }).then(r => {
      this.addressList = r.data.data
    })
    this.getLocation()
    this.form.enterprise_id = this.$route.query.enterprise_id
    request({
      url: 'feedback/field',
      data: { enterprise_id: this.form.enterprise_id }
    }).then(r => {
      this.fieldList = r.data.data
    })
    request({
      url: 'dropdown/category_cascade',
      data: { busi_type: 4, enterprise_id: this.form.enterprise_id }
    }).then(r => {
      this.faultList = r.data.data
    })
    request({
      url: 'dropdown/category_cascade',
      data: { busi_type: 5, enterprise_id: this.form.enterprise_id }
    }).then(r => {
      this.productCategoryList = r.data.data
    })
    this.$router.replace({ path: '/single', query: {"enterpriseId":this.$route.query.enterprise_id}})
  },
  methods: {
    getLocation() {
      const that = this
      AMap.plugin('AMap.Geolocation', function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        })

        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', onComplete);
        AMap.event.addListener(geolocation, 'error', onError);

        function onComplete(data) {
          // data是具体的定位信息
          const provinceName = data.addressComponent.province
          const cityName = data.addressComponent.city
          const areaName = data.addressComponent.district
          const region = []
          let province = null
          let city = null
          that.addressList.forEach(item => {
            if (item.name === provinceName) {
              province = item
              region.push(item.code)
            }
          })
          if (province) {
            province.children.forEach(item => {
              if (item.name === cityName){
                city = item
                region.push(item.code)
                return
              }
            })
          }
          if (city) {
            city.children.forEach(item => {
              if (item.name === areaName){
                region.push(item.code)
                return
              }
            })
          }
          that.$set(that.form, 'area', region)
          const address = data.addressComponent.township + data.addressComponent.street + data.addressComponent.streetNumber
          that.$set(that.form, 'address', address)
        }

        function onError(data) {
          // 定位出错
          console.log('定位失败错误：', data);
          // 调用ip定位
          // self.getLngLatLocation();
        }
      })
    },
    closeModal () {
      this.visible = false
      window.location.reload()
    },
    showQrCode (id) {
      const qrUrl = window.location.origin + '/#/fh?data_type=2&id=' + id
      this.visible = true
      if (!this.qrCode) {
        this.qrCode = new QRCode(this.$refs.qrCodeUrl, {
          text: qrUrl, // 需要转换为二维码的内容
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
        var myCanvas = document.getElementsByTagName('canvas')[0];
        this.qrSrc = myCanvas.toDataURL("image/png")
      }
    },
    categoryChange (val) {
      const that = this
      const p = { enterprise_id: this.form.enterprise_id, contains_disabled: 2}
      delete this.form.product_id
      if(val[0]){
        p.big_category_id = val[0]
      }
      if(val[1]){
        p.small_category_id = val[1]
      }
      request({
        url: 'dropdown/goods',
        data: p
      }).then(r => {
        that.$nextTick(() => {
          that.productList = r.data.data
        })
      })
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          data.attachment_list = this.form.attachment_list
          if (data.area) {
            if (data.area[0]){
              data.province_code = data.area[0]
            }
            if (data.area[1]){
              data.city_code = data.area[1]
            }
            if (data.area[2]){
              data.area_code = data.area[2]
            }
          }
          if (data.fault) {
            if (data.fault[0]) {
              data.big_fault_id = data.fault[0]
            }
            if (data.fault[1]) {
              data.small_fault_id = data.fault[1]
            }
          }
          this.loading = true
          request({
            url: 'feedback/customer_add',
            data: data
          }).then(r => {
            if (r.data.success) {
              alert('您的报修申请已经提交给客服人员，稍后会跟您联系，请保持电话畅通')
              this.form = {}
              this.showQrCode(r.data.data.id)
            } else if (r.data.error_code === enums.STATE.FEEDBACK_REPORT_FAULT_MOBILE.v) {
              alert(r.data.msg)
            } else {
              alert('网络异常,请稍后重试')
            }
          }).finally(() => {
            this.loading = false
          })
        }
      });
    }
  }
}
</script>
<style lang="less" scoped>
@import "../assets/css/form.less";
.container{
  padding: 8px;
  padding-top: 58px;
  .form_container{
    background-color: #fff;
  }
}
.qr_title{
  text-align: center;
  margin-bottom: 5px;
  font-size: 18px;
}
/deep/ .ant-modal-body{
  padding: 10px;
}
</style>
